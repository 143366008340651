import React, { useState } from "react";
import "./PopUpModal.css";

interface ModalProps {
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: (data: { userName: string; email: string }) => void;
  confirmText?: string;
  cancelText?: string;
}

const Modal: React.FC<ModalProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    onConfirm({ userName, email });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        {/* Close Button */}
        <button className="close-button" onClick={onCancel}>
          &times;
        </button>

        <h2>{title}</h2>
        <p>{message}</p>
        <form>
          <div className="form-group">
            <label htmlFor="userName">User Name:</label>
            <input
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter your name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
        </form>
        <div className="modal-buttons">
          <button
            type="button"
            className="send-button"
            onClick={handleSubmit}
            disabled={!userName || !email}
          >
            {confirmText}
          </button>
          <button className="cancel-button" onClick={onCancel}>
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
