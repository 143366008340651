import React, { useState, useEffect } from "react";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";

// Import your components and pages
import LogoAnimation from "./logoAnimation";
import Error from "./notifications/Error";
import Neutral from "./notifications/Neutral";
import TolkPage from "./tolkchat";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
import WaaromPage from "./pages/waarom";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import RegisterOrganisation from "./pages/registerorg";
import RegisterTenPlus from "./pages/registerTenPlusOrg";
import History from "./pages/history";
import Domein from "./pages/profiel";
import Abonnementen from "./pages/abbonement";
import Contract from "./pages/contract";
import ProfielOverzicht from "./pages/profieloverzicht";
import FAQ from "./pages/faq";
import Ontwikkeling from "./pages/ontwikkeling";
import DemoPilot from "./pages/demopilot";
import Feedback from "./pages/contactfeedback";
import ChatPage from "./pages/qrchat";
import WachtwoordVergeten from "./pages/wachtwoordVergeten";
import ResetPassword from "./pages/wachtwoordNieuw";
import QRCodePage from "./pages/qrcode";
import Patients from "./pages/patienten";
import MijnBedrijf from "./pages/mijnbedrijf";
import MijnBedrijfOverzicht from "./pages/mijnbedrijfoverzicht";
import PaymentSuccess from "./pages/paymentsuccess";

import "./App.css";

import { getJwtToken } from "./auth";
import config from "./config";
import TranslationPage from "./pages/translation";
import useConnectivityStatus from "./functions/useConnectivityStatus";

const reactServer = config.reactServer;

const App: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [isInTolkChat, setIsInTolkChat] = useState(false);

  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );

  const { isOnline, isServerReachable } = useConnectivityStatus();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [neutralMessage, setNeutralMessage] = useState<string | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  // Get all the data from the user
  const [profile, setProfile] = useState({
    is_admin: false,
    is_org_admin: false,
    naam: false,
    organisation: ["", 0, 0, 0],
  });

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
            const errorMsg = "U bent momenteel niet ingelogd op het portaal.";
            setNeutralMessage(errorMsg);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentPath.includes("/tolkchat")) {
      setIsInTolkChat(true);
    } else {
      setIsInTolkChat(false);
    }
  }, [currentPath]);

  const handleNavigation = (
    path: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isInTolkChat) {
      const confirmNavigation = window.confirm(
        "Uw gespreksdata zal verloren gaan. Weet u zeker dat u het huidige gesprek wilt afsluiten?"
      );
      if (!confirmNavigation) {
        event.preventDefault();
      } else {
        setCurrentPath(path);
        setMenuOpen(false); // Close the menu
      }
    } else {
      setCurrentPath(path);
      setMenuOpen(false); // Close the menu
    }
  };

  const handleCloseError = () => {
    setErrorMessage(null);
  };

  const handleCloseNeutral = () => {
    setNeutralMessage(null);
  };

  useEffect(() => {
    if (!isOnline) {
      setErrorMessage("Geen internet. Check uw verbinding");
    } else if (!isServerReachable) {
      setErrorMessage("Kan de server niet bereiken. Probeer het later opnieuw.");
    } else {
      setErrorMessage(null);
    }
  }, [isOnline, isServerReachable]);

  // Determine if it's mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuOpen]);

  return (
    <div>
      {animationFinished === false && (
        <LogoAnimation onAnimationEnd={handleAnimationEnd} />
      )}
      {animationFinished && (
        <Router>
          <div>
            <nav className={`navigation ${scrolled ? "scrolled" : ""}`}>
              <div className={`nav-container ${isMobile ? "ismobile" : ""}`}>
                {isMobile ? (
                  <>
                    <div className="nav-mobile-container">
                      <Link
                        to="/tolkchat"
                        onClick={(e) => handleNavigation("/tolkchat", e)}
                      >
                        <img
                          src="./logo.png"
                          alt="Logo"
                          className="nav-logo-mobile"
                        />
                      </Link>
                      <div
                        className={`ham-menu ${menuOpen ? "active" : ""}`}
                        onClick={() => setMenuOpen(!menuOpen)}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="nav-links">
                    {profile.is_admin || profile.is_org_admin ? (
                      <Link
                        className="nav-link"
                        to="/profieloverzicht"
                        onClick={(e) => handleNavigation("/profieloverzicht", e)}
                      >
                        Profieloverzicht
                      </Link>
                    ) : (
                      <Link
                        className="nav-link"
                        to="https://wellcomtranslation.com/"
                        onClick={(e) => handleNavigation("/waarom", e)}
                      >
                        Waarom Wellcom?
                      </Link>
                    )}
                    <Link
                      className="nav-link"
                      to={profile.naam ? "/feedback" : "/contact"}
                      onClick={(e) =>
                        handleNavigation(profile.naam ? "/feedback" : "/contact", e)
                      }
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {profile.naam ? "Feedback" : "Contact"}
                    </Link>
                    <Link className="nav-link show-on-mobile-only" to={"/tolkchat"}>
                      Tolk Chat
                    </Link>
                    <Link
                      className="nav-link"
                      to={
                        profile.organisation && profile.organisation[3]
                          ? "/mijnbedrijf"
                          : "/faq"
                      }
                      onClick={(e) =>
                        handleNavigation(
                          profile.organisation && profile.organisation[3]
                            ? "/mijnbedrijf"
                            : "/faq",
                          e
                        )
                      }
                    >
                      {profile.organisation && profile.organisation[3]
                        ? profile.organisation[0]
                        : "FAQ"}
                    </Link>
                    <Link
                      to={profile.naam ? "/tolkchat" : "/login"}
                      onClick={(e) =>
                        handleNavigation(profile.naam ? "/tolkchat" : "/login", e)
                      }
                    >
                      <img src="./logo.png" alt="Logo" className="nav-logo" />
                    </Link>
                    {profile.is_admin ? (
                      <Link
                        className="nav-link"
                        to="/bedrijfoverzicht"
                        onClick={(e) => handleNavigation("/bedrijfoverzicht", e)}
                      >
                        Bedrijfoverzicht
                      </Link>
                    ) : (
                      <Link
                        className="nav-link"
                        to={profile.naam ? "/tolkchat" : "/login"}
                        onClick={(e) => handleNavigation("/tolkchat", e)}
                      >
                        Tolk Chat
                      </Link>
                    )}

                    <Link
                      className="nav-link"
                      to={profile.naam ? "/domein" : "/ontwikkeling"}
                      onClick={(e) =>
                        handleNavigation(
                          profile.naam ? "/domein" : "/ontwikkeling",
                          e
                        )
                      }
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {profile.naam ? "Profiel" : "Ontwikkeling"}
                    </Link>
                    <Link
                      className="nav-link show-on-mobile"
                      to={profile.naam ? "/domein" : "/login"}
                      onClick={(e) =>
                        handleNavigation(profile.naam ? "/domein" : "/login", e)
                      }
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {profile.naam
                        ? isHovered
                          ? profile.naam
                          : profile.naam
                        : "Login"}
                    </Link>
                  </div>
                )}
              </div>
              {isMobile && menuOpen && (
                // Show dropdown menu on mobile when menu is open
                <div className="nav-links mobile open">
                  {profile.is_admin || profile.is_org_admin ? (
                    <Link
                      className="nav-link"
                      to="/profieloverzicht"
                      onClick={(e) =>
                        handleNavigation("/profieloverzicht", e)
                      }
                    >
                      Profieloverzicht
                    </Link>
                  ) : (
                    <Link
                      className="nav-link"
                      to="/waarom"
                      onClick={(e) => handleNavigation("/waarom", e)}
                    >
                      Waarom Wellcom?
                    </Link>
                  )}
                  <Link
                    className="nav-link"
                    to={profile.naam ? "/feedback" : "/contact"}
                    onClick={(e) =>
                      handleNavigation(
                        profile.naam ? "/feedback" : "/contact",
                        e
                      )
                    }
                  >
                    {profile.naam ? "Feedback" : "Contact"}
                  </Link>
                  {/* Add Tolk Chat link in mobile menu */}
                  <Link
                    className="nav-link"
                    to={profile.naam ? "/tolkchat" : "/login"}
                    onClick={(e) =>
                      handleNavigation(profile.naam ? "/tolkchat" : "/login", e)
                    }
                  >
                    Tolk Chat
                  </Link>
                  <Link
                    className="nav-link"
                    to={
                      profile.organisation && profile.organisation[3]
                        ? "/mijnbedrijf"
                        : "/faq"
                    }
                    onClick={(e) =>
                      handleNavigation(
                        profile.organisation && profile.organisation[3]
                          ? "/mijnbedrijf"
                          : "/faq",
                        e
                      )
                    }
                  >
                    {profile.organisation && profile.organisation[3]
                      ? profile.organisation[0]
                      : "FAQ"}
                  </Link>
                  {profile.is_admin ? (
                    <Link
                      className="nav-link"
                      to="/bedrijfoverzicht"
                      onClick={(e) =>
                        handleNavigation("/bedrijfoverzicht", e)
                      }
                    >
                      Bedrijfoverzicht
                    </Link>
                  ) : null}
                  <Link
                    className="nav-link"
                    to={profile.naam ? "/domein" : "/ontwikkeling"}
                    onClick={(e) =>
                      handleNavigation(
                        profile.naam ? "/domein" : "/ontwikkeling",
                        e
                      )
                    }
                  >
                    {profile.naam ? "Profiel" : "Ontwikkeling"}
                  </Link>
                  <Link
                    className="nav-link"
                    to={profile.naam ? "/domein" : "/login"}
                    onClick={(e) =>
                      handleNavigation(profile.naam ? "/domein" : "/login", e)
                    }
                  >
                    {profile.naam ? profile.naam : "Login"}
                  </Link>
                </div>
              )}
            </nav>

            <div className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={profile.naam ? <TolkPage /> : <LoginPage />}
                />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/waarom" element={<WaaromPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route
                  path="/register-organisation"
                  element={<RegisterOrganisation />}
                />
                <Route
                  path="/register-10-plus-organisation"
                  element={<RegisterTenPlus />}
                />
                <Route path="/tolkchat" element={<TolkPage />} />
                <Route path="/history" element={<History />} />
                <Route path="/domein" element={<Domein />} />
                <Route path="/abonnement" element={<Abonnementen />} />
                <Route path="/contract" element={<Contract />} />
                <Route path="/profieloverzicht" element={<ProfielOverzicht />} />
                <Route path="/translate" element={<TranslationPage />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/ontwikkeling" element={<Ontwikkeling />} />
                <Route path="/demopilot" element={<DemoPilot />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/chat/:conversationId" element={<ChatPage />} />
                <Route path="/new_chat/:conversationId" element={<ChatPage />} />
                <Route
                  path="/wachtwoord-vergeten"
                  element={<WachtwoordVergeten />}
                />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/mijnbedrijf" element={<MijnBedrijf />} />
                <Route
                  path="/bedrijfoverzicht"
                  element={<MijnBedrijfOverzicht />}
                />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                {/* <Route path="/patientenoverzicht" element={<Patients />} /> */}
              </Routes>
            </div>

            <footer className="footer">
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/company/wellcom-translation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./linkedin-logo.png" alt="LinkedIn" />
                </a>
                <a
                  href="https://www.wellcom-translation.nl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./globe-logo.png" alt="Website" />
                </a>
              </div>
              <div className="additional-links">
                <Link to="/faq">FAQ</Link>
                <Link to="/ontwikkeling">Ontwikkeling</Link>
                <a href="/./bestanden/privacy-statement.pdf" download>
                  Privacy Statement
                </a>
                <a href="/./bestanden/Usage_Manual.pdf" download>
                  Gebruikershandleiding
                </a>
              </div>
              <div className="copyright">
                {new Date().getFullYear()} Wellcom. all rights reserved.
              </div>
            </footer>

            {errorMessage && (
              <Error message={errorMessage} onClose={handleCloseError} />
            )}
            {neutralMessage && (
              <Neutral message={neutralMessage} onClose={handleCloseNeutral} />
            )}
          </div>
        </Router>
      )}
    </div>
  );
};

export default App;
