import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../App.css"; // Import the CSS file
import "./profiel.css"; // Import the custom CSS file for profile page
import ErrorMessage from "../notifications/Error";

import { getJwtToken, logout } from "../auth";
import {
  allLanguages,
  TranslateMapping,
  SpeechToTextMapping,
  TextToSpeechMapping,
} from "../mappings/microsoft";

// Access the server variable
import config from "../config";
const reactServer = config.reactServer;

const ProfilePage: React.FC = () => {
  const [userData, setUserData] = useState<any>(null); // State to store user data
  const [newPatientName, setNewPatientName] = useState<string>("");
  const [newPatientSurname, setNewPatientSurname] = useState<string>("");
  const [newPatientLanguage, setNewPatientLanguage] = useState<string>("");
  const [searchPatientName, setSearchPatientName] = useState<string>("");
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [rownaam, setRowNaam] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("naam"); // Default to 'naam'
  const [newPatientID, setNewPatientID] = useState("");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState<boolean>(false); // State for 2FA

  const navigate = useNavigate();

  // Get all the data from the user
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
          setIsTwoFactorEnabled(Boolean(data.twofactor));
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  useEffect(() => {
    // Simulate fetching user data when component mounts
    const fakeUserData = {
      naam: "Thijs Rood",
      email: "thijs.rood@example.com",
      accounttype: "dokter", // Change to 'patient' for patient
      RegistrationConfirmed: true,
      twofactor: ["123456", "30-01-2024 15:20:10"],
      patients: [
        { naam: "Jim", surname: "Vechtold", language: "English" },
        { naam: "Jan", surname: "de Weert", language: "Spanish" },
        { naam: "Charles", surname: "den Tex", language: "French" },
      ],
    };
    setUserData(fakeUserData);
  }, []);

  const handleAddPatient = async () => {
    const newPatient = {
      naam: newPatientName,
      surname: newPatientSurname,
      language: newPatientLanguage,
    };

    try {
      const token = getJwtToken();

      const response = await fetch(reactServer + "account/add-patient", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPatient),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedProfile = await response.json();

      // Update the local state with the updated profile data
      setProfile(updatedProfile);
    } catch (error) {
      console.error("Error adding patient:", error);
    }

    // update live on the page too
    const updatedPatients = [...userData.patients, newPatient];
    setUserData({ ...userData, patients: updatedPatients });

    // Clear input fields after adding patient
    setNewPatientName("");
    setNewPatientSurname("");
    setNewPatientLanguage("");
    // window.location.reload();
  };

  const handleDeletePatient = async (patientId) => {
    try {
      const token = getJwtToken();

      const response = await fetch(
        reactServer + `account/delete-patient/${patientId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedProfile = await response.json();

      // Update the local state with the updated profile data
      setProfile(updatedProfile);

      // Update the local state to remove the deleted patient
      const updatedPatients = userData.patients.filter(
        (patient) => patient.id !== patientId
      );
      setUserData({ ...userData, patients: updatedPatients });
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  };

  const handleSearchPatient = () => {
    // Simulate searching for a patient
    const foundPatient = userData.patients.find(
      (patient: any) =>
        `${patient.naam} ${patient.surname}` === searchPatientName
    );
    setSelectedPatient(foundPatient);
  };

  const handleRowClick = (index: number, naam: string) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    setRowNaam(naam);
  };

  const handleStartConversation = () => {
    // Simulate starting a conversation with the selected patient
    console.log(
      `Starting conversation with ${selectedPatient.naam} ${selectedPatient.surname}`
    );
  };

  const handleTwoFactorToggle = async () => {
    try {
      const token = getJwtToken();
      const newStatus = !isTwoFactorEnabled;

      const response = await fetch(reactServer + "account/settings/2fa", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: profile.email,
          twofactorEnabled: newStatus,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Update the local state with the new 2FA status
      setIsTwoFactorEnabled(newStatus);
    } catch (error) {
      console.error("Error updating 2FA status:", error);
    }
  };

  const handleLogout = () => {
    // Simulate logout action, you can implement your actual logout logic here
    logout();
    navigate("/", { replace: true });
    window.location.reload();
    console.log("Logged out");
  };

  const handleConfirmDelete = () => {
    const isConfirmed = window.confirm(
      "Weet u zeker dat u uw account wilt verwijderen? Door uw account te verwijderen, verliest u direct de toegang tot uw account en kunt u geen gebruik meer maken van de eventuele resterende tijd binnen uw betaalde abonnement. Dit proces is definitief en onomkeerbaar."
    );

    if (isConfirmed) {
      handleDeleteUser(); // Call the actual delete function
    }
  };

  const handleDeleteUser = async () => {
    console.log("hier");
    console.log(profile.email);
    try {
      const token = getJwtToken();
      const response = await fetch(reactServer + "/account/delete-account", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: profile.email }), // assuming `user.id` holds the user's index
      });

      if (response.ok) {
        // Handle successful deletion (like redirecting the user or showing a message)
        logout();
        console.log("Account successfully deleted.");
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        // Handle errors
        console.error("Failed to delete account.");
        setErrorMessage(
          "Kon het account momenteel niet verwijderen. Contacteer de technische afdeling."
        );
      }
    } catch (error) {
      console.error("An error occurred while deleting the account:", error);
    }
  };

  const handleCloseError = () => {
    setErrorMessage(null);
  };

  return (
    <div className="profile-page-container">
      {userData && profile && (
        <>
          {/* Left Block: User Details */}
          <div className="user-details-block">
            <h2 className="title">{profile.naam}'s Profiel</h2>

            <div className="profile-details">
              <h3 className="profile-header-margin">Profiel Details</h3>
              <p className="profile-text-margin">
                E-mailadres: {profile.email}
              </p>
              <p className="profile-text-margin">
                Aantal patiënten: {profile.patienten.length}
              </p>
              <p className="profile-text-margin">
                Gebruik aantal: {Math.ceil(profile.usage[0])} TolkChat's
              </p>
              {/* <p className="profile-text-margin">
                Gebruik tijd: {Math.ceil(profile.usage[3])} secondes
              </p> */}

              {profile && (
                <p className="profile-text-margin">
                  Gebruik tijd: {Math.ceil(profile.usage?.[3]) ?? "N/A"} /{" "}
                  {profile.is_unlimited
                    ? "Onbekend"
                    : profile.limit !== undefined
                    ? `${profile.limit} secondes`
                    : ""}
                </p>
              )}
              {profile?.is_org_admin ? (
                <p className="profile-text-margin">
                  Gebruikers: {profile.org_users_count ?? "N/A"} /
                  {profile.org_max_deelnemers ?? "N/A"}
                </p>
              ) : null}

              {/* {profile && (
                <span>
                  Gebruik tijd: {profile.usage?.[3] ?? "N/A"} /{" "}
                  {profile.is_unlimited ? "Onbekend" : profile.limit ?? "N/A"}
                </span>
              )} */}
              <p className="profile-text-margin">
                Registratie bevestigd:{" "}
                {userData.RegistrationConfirmed ? "Ja" : "Nee"}
              </p>
            </div>

            {/* Subscription Info */}
            <div className="subscription-section">
              <h3 className="profile-header-margin">Abonnement Details</h3>
              <div className="subscription-info">
                <p className="profile-text-margin">
                  Abonnementstype: {profile.subscription}
                </p>
                {profile.subscription?.[1] &&
                new Date(profile.subscription_end_date) < new Date() ? (
                  <p className="profile-text-margin subscription-expired">
                    Uw abonnement is verlopen!
                  </p>
                ) : (
                  <p className="profile-text-margin subscription-valid">
                    Abonnement is actief tot{" "}
                    {new Date(
                      profile.subscription_end_date
                    ).toLocaleDateString()}
                  </p>
                )}
                <p className="profile-text-margin">
                  Abonnement aangeschaft op{" "}
                  {new Date(
                    profile.subscription_start_date
                  ).toLocaleDateString()}
                </p>
                <p className="profile-text-margin">
                  {profile.subscription !== "Demo" ? (
                    `Abonnement wordt verlengd op ${new Date(
                      profile.subscription_end_date
                    ).toLocaleDateString()}`
                  ) : (
                    <></>
                  )}
                </p>
                <a href="/#/abonnement" className="abbonement-button">
                  Abonnement wijzigen
                </a>
              </div>
            </div>

            {/* Two-Factor Authentication */}
            <h3 className="profile-header-margin">Instellingen</h3>
            <div className="two-factor-auth">
              <label>
                <strong>Tweestapsverificatie</strong>
                <input
                  type="checkbox"
                  checked={isTwoFactorEnabled}
                  onChange={handleTwoFactorToggle}
                />
              </label>
            </div>

            <hr className="logout-line" />
            <div className="centerme">
              <button className="logout-button" onClick={handleLogout}>
                Uitloggen
              </button>
              <button className="delete-button" onClick={handleConfirmDelete}>
                Verwijder Account
              </button>
            </div>
            {/* <hr className='patient-line' /> */}
          </div>

          {/* Right Block: Add/Search Patient Section */}
          {userData.accounttype === "dokter" && (
            <div className="add-patient-block">
              {/* Organisatie Details */}
              {/* <div>
                        <h3 className='organisation-title'>Mijn Zorginstellingen</h3>
                        {
                            profile.organisation && profile.organisation.length >= 8 && profile.organisation[3] ? (
                                <div>
                                    <p className='organisation-naam'>{profile.organisation[0]}</p>
                                    <p className='organisation-text'>
                                        Uw organisatie is {profile.organisation[4] ? 'wel' : 'nog niet'} bevestigd.
                                    </p>
                                    <p className='organisation-text'>
                                        {profile.organisation[5] ? 'U bent administrator van deze organisatie.' : ''}
                                    </p>
                                    <p className='organisation-text'>Deelnemers: {profile.organisation[7]}</p>
                                    <p className='organisation-text'>Code: {profile.organisation[1]}</p>
                                </div>
                            ) : (
                                <div>
                                    <p className='organisation-text'>
                                        U bent nog geen deel van een organisatie. Mocht u deel van uw organisatie willen worden vraag dit dan aan bij uw beheerder.
                                    </p>
                                    <p className='organisation-naam'>
                                        {profile.organisation && profile.organisation[0] !== 'Geen organisatie' ? (
                                            <div>U heeft een aanvraag gedaan bij {profile.organisation[0]}</div>
                                        ) : (
                                            'U heeft geen aanvraag gedaan bij een organisatie.'
                                        )}
                                    </p>
                                </div>
                            )
                        }
                    </div> */}

              <div>
                <h3 className="voeg-patient-title">Voeg Patiënt Toe</h3>
                <div className="centerme">
                  {/* <div className="radio-buttons">
                    <label>
                      <input
                        type="radio"
                        value="naam"
                        checked={selectedOption === "naam"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      Naam
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="patientID"
                        checked={selectedOption === "patientID"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      patiëntID
                    </label>
                  </div> */}
                  <div className="patient-inputs">
                    {/* {selectedOption === "naam" ? (
                      <>
                        <input
                          type="text"
                          className="patient-input"
                          placeholder="Voornaam"
                          value={newPatientName}
                          onChange={(e) => setNewPatientName(e.target.value)}
                        />
                        <input
                          type="text"
                          className="patient-input"
                          placeholder="Achternaam"
                          value={newPatientSurname}
                          onChange={(e) => setNewPatientSurname(e.target.value)}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="patient-input"
                          placeholder="patiëntID"
                          value={newPatientName}
                          onChange={(e) => setNewPatientName(e.target.value)}
                        />
                      </>
                    )} */}
                    <>
                      <input
                        type="text"
                        className="patient-input"
                        placeholder="patiëntID"
                        value={newPatientName}
                        onChange={(e) => setNewPatientName(e.target.value)}
                      />
                    </>

                    <select
                      className="selectorpatient"
                      value={newPatientLanguage}
                      onChange={(e) => setNewPatientLanguage(e.target.value)}
                    >
                      <option value="">Selecteer taal</option>
                      {allLanguages.map((lang) => (
                        <option key={lang} value={lang}>
                          {lang}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    className="add-patient-button"
                    onClick={handleAddPatient}
                  >
                    Toevoegen
                  </button>
                </div>
              </div>

              <hr className="organisation-line" />
              <h3 className="zoek-patient-titel">Patiëntenlijst</h3>
              <div className="patient-search-inputs">
                <input
                  type="text"
                  className="inputfield"
                  placeholder="PatiëntID"
                  value={searchPatientName}
                  // onChange={(e) => setSearchPatientName(e.target.value)}
                />
                <button
                  className="add-patient-button"
                  onClick={handleSearchPatient}
                >
                  Zoek
                </button>
              </div>
              {selectedPatient && (
                <div className="selected-patient-details">
                  <p>
                    Selected Patient: {selectedPatient.naam}{" "}
                    {selectedPatient.surname}
                  </p>
                  <button
                    className="start-conversation-button"
                    onClick={handleStartConversation}
                  >
                    Start Gesprek
                  </button>
                </div>
              )}
              <div className="patient-table-container">
                <table className="patient-table">
                  <thead>
                    <tr>
                      <th>PatiëntID</th>
                      <th>Taal</th>
                      <th>Verwijderen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile.patienten.map((patient: any, index: number) => (
                      <tr
                        key={index}
                        onClick={() =>
                          handleRowClick(
                            index,
                            patient.naam + " " + patient.surname
                          )
                        }
                        className={index === selectedRowIndex ? "selected" : ""}
                      >
                        <td>
                          {patient.naam} {patient.surname}
                        </td>
                        <td>{patient.language}</td>
                        <td className="bincontainer">
                          <img
                            alt="bin"
                            className={"bin"}
                            src={"/bin_black.png"}
                            onMouseOver={(e) =>
                              (e.currentTarget.src = "/bin_red.png")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.src = "/bin_black.png")
                            }
                            onClick={() => handleDeletePatient(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {rownaam && (
                  <div>De naam van de patiënt die moet laden is {rownaam}</div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {errorMessage && (
        <ErrorMessage message={errorMessage} onClose={handleCloseError} />
      )}
    </div>
  );
};

export default ProfilePage;
