import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import './eindegesprek.css';

// Access the server variable
import config from '../config';
import { getJwtToken } from '../auth';
const reactServer = config.reactServer;

interface EindeGesprekProps {
  conversationId: string;
  transcript: any[];
  transcript_dutch: any[];
  transcript_foreign: any[];
}

// FeedbackSection Component
const FeedbackSection = ({
  statements,
  currentQuestion,
  ratings,
  ratingsSubmitted,
  feedbackDropdownVisible,
  feedbackText,
  feedbackSent,
  handleRatingChange,
  handleSubmitRatings,
  handleFeedbackButtonClick,
  handleFeedbackTextChange,
  handleFeedbackSubmit,
  setCurrentQuestion,
}) => {
  const ratingOptions = [
    { value: 1, label: 'Helemaal niet mee eens' },
    { value: 2, label: 'Niet mee eens' },
    { value: 3, label: 'Neutraal' },
    { value: 4, label: 'Mee eens' },
    { value: 5, label: 'Helemaal mee eens' },
  ];

  return (
    <div className="feedback-section eindegesprek-page">
      <h2>Feedback</h2>

      {!ratingsSubmitted ? (
        <div className="rating-container">
          <strong>{statements[currentQuestion][0]}</strong>
          <p>{statements[currentQuestion][1]}</p>
          <div className="rating-options">
            {ratingOptions.map((option) => (
              <button
                key={option.value}
                className={`rating-button ${ratings[currentQuestion] === option.value ? 'selected' : ''}`}
                onClick={() => handleRatingChange(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <div className="navigation-buttons">
            <button
              className="nav-button"
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
              disabled={currentQuestion === 0}
            >
              Vorige
            </button>
            {currentQuestion < statements.length - 1 ? (
              <button
                className="nav-button"
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
                disabled={ratings[currentQuestion] === 0}
              >
                Volgende
              </button>
            ) : (
              <button
                className="nav-button"
                onClick={handleSubmitRatings}
                disabled={ratings[currentQuestion] === 0}
              >
                Versturen
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="thank-you-message">
          <p>Bedankt voor uw feedback!</p>
        </div>
      )}

      <button className="feedback-button" onClick={handleFeedbackButtonClick}>
        Feedback Schrijven
      </button>
      {feedbackDropdownVisible && (
        <div className="feedback-dropdown">
          <textarea
            className="feedback-textarea"
            value={feedbackText}
            onChange={handleFeedbackTextChange}
            placeholder="Schrijf uw feedback hier..."
          />
          <button className="feedback-submit-button" onClick={handleFeedbackSubmit}>
            Versturen
          </button>
          {feedbackSent && <p>Uw feedback is verzonden. Dank u zeer.</p>}
        </div>
      )}
    </div>
  );
};

const EindeGesprekPage: React.FC<EindeGesprekProps> = ({
  conversationId,
  transcript,
  transcript_dutch,
  transcript_foreign,
}) => {
  const [summaryType, setSummaryType] = useState('SOEP');
  const [exportType, setExportType] = useState('PDF');
  const [feedbackDropdownVisible, setFeedbackDropdownVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [loadingSamenvatting, setLoadingSamenvatting] = useState(false);
  const [samenvattingText, setSamenvattingText] = useState('');

  // Define the statements
  const statements = [
    ['Patiëntervaring:', 'TolkChat stelt de patiënt in staat om duidelijk en in de eigen taal te communiceren.'],
    [
      'Kwaliteit van zorg:',
      'TolkChat zorgt voor betrouwbare overdracht van medische informatie, waardoor het risico op miscommunicatie wordt verlaagd.',
    ],
    [
      'Kostenbesparing en efficiëntie:',
      'TolkChat bespaart aanzienlijk tijd bij taalbarrières en vermindert de administratieve lasten.',
    ],
    [
      'Zorgverleningservaring:',
      'TolkChat is gebruiksvriendelijk en intuïtief, waardoor er meer aandacht is voor patiënten met taalbarrières.',
    ],
    [
      'Vergelijking met bestaande oplossingen:',
      'TolkChat is effectiever en betrouwbaarder dan bestaande vertaaloplossingen.',
    ],
    ['Net Promotor Score (NPS):', 'Ik zou TolkChat aanbevelen aan andere zorgverleners.'],
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [ratings, setRatings] = useState<number[]>(Array(statements.length).fill(0));
  const [ratingsSubmitted, setRatingsSubmitted] = useState(false);

  // Handlers

  const handleExportGesprek = async () => {
    const token = getJwtToken();

    if (token) {
      try {
        const response = await fetch(reactServer + 'conversation/export_transcript', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: exportType,
            id: conversationId,
            conversation_original: transcript,
            conversation_dutch: transcript_dutch,
            conversation_foreign: transcript_foreign,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to export transcript');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, `WellComm_Transcript_${exportType}_${conversationId}_${filename}`);
      } catch (error) {
        console.error('Error exporting transcript:', error);
      }
    }
  };

  const handleSamenvatten = async () => {
    setLoadingSamenvatting(true);
    const token = getJwtToken();

    if (token) {
      try {
        const response = await fetch(reactServer + 'conversation/generate_summary', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: summaryType,
            id: conversationId,
            conversation_dutch: transcript_dutch,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate summary');
        }

        const data = await response.json();

        // Assuming the response contains the summary text in data.summary_text
        setSamenvattingText(data.summary_text);
        console.log('Summary generated successfully:', data.summary_text);

        setLoadingSamenvatting(false);
      } catch (error) {
        console.error('Error generating summary:', error);
        setLoadingSamenvatting(false);
      }
    }
  };

  const handleRatingChange = (newRating: number) => {
    const updatedRatings = [...ratings];
    updatedRatings[currentQuestion] = newRating;
    setRatings(updatedRatings);

    // Automatically move to the next question if not the last one
    if (currentQuestion < statements.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleSubmitRatings = async () => {
    try {
      const response = await fetch(`${reactServer}feedback/update_counts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ratings: ratings }),
      });

      if (!response.ok) {
        throw new Error('Failed to update rating counts');
      }

      console.log('Rating counts updated successfully');
      setRatingsSubmitted(true);
    } catch (error) {
      console.error('Error updating rating counts:', error);
    }
  };

  const handleFeedbackButtonClick = () => {
    setFeedbackDropdownVisible(!feedbackDropdownVisible);
    setFeedbackSent(false); // Reset feedback sent status when toggling
  };

  const handleFeedbackTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value);
  };

  const handleFeedbackSubmit = async () => {
    try {
      const response = await fetch(`${reactServer}feedback/send_feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback: feedbackText }),
      });

      if (!response.ok) {
        throw new Error('Failed to send feedback');
      }

      console.log('Feedback sent successfully');
      setFeedbackSent(true);
      setFeedbackText('');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  // Function to export the summary text
  const handleExportSamenvatting = async () => {
    const token = getJwtToken();

    if (token) {
      try {
        const response = await fetch(reactServer + 'conversation/export_summary_text', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: exportType,
            id: conversationId,
            summary_text: samenvattingText,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to export summary');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, `WellComm_Summary_${exportType}_${conversationId}_${filename}`);
      } catch (error) {
        console.error('Error exporting summary:', error);
      }
    }
  };

  const handleCopySamenvatting = () => {
    navigator.clipboard
      .writeText(samenvattingText)
      .then(() => {
        alert('Samenvatting is gekopieerd naar het klembord.');
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  return (
    <div className="eindegesprek-container">
      <div className="eindegesprek-left">
        <div className="eindegesprek-page">
          <h2>Exporteer Gesprek</h2>

          {/* Export Conversation Section */}
          <div className="export-container">
            <div className="dropdown-container">
              <label className="dropdown-label">Export Type:</label>
              <select className="dropdown" value={exportType} onChange={(e) => setExportType(e.target.value)}>
                <option value="PDF">PDF</option>
                <option value="Text">Text</option>
              </select>
              <button className="action-button" onClick={handleExportGesprek}>
                Exporteer Gesprek
              </button>
            </div>
          </div>

          {/* Summary Generation Section */}
          <div className="export-container">
            <div className="dropdown-container">
              <label className="dropdown-label">Samenvatting Type:</label>
              <select className="dropdown" value={summaryType} onChange={(e) => setSummaryType(e.target.value)}>
                <option value="SOEP">SOEP</option>
                <option value="NHG">NHG</option>
                <option value="Specialist">Specialist</option>
              </select>
              <button className="action-button" onClick={handleSamenvatten}>
                Samenvatten
              </button>
              {loadingSamenvatting && <div className="loader">Bezig met het maken van een samenvatting...</div>}
            </div>
          </div>
        </div>

        {/* Feedback Section for Desktop */}
        <div className="desktop-only">
          <FeedbackSection
            statements={statements}
            currentQuestion={currentQuestion}
            ratings={ratings}
            ratingsSubmitted={ratingsSubmitted}
            feedbackDropdownVisible={feedbackDropdownVisible}
            feedbackText={feedbackText}
            feedbackSent={feedbackSent}
            handleRatingChange={handleRatingChange}
            handleSubmitRatings={handleSubmitRatings}
            handleFeedbackButtonClick={handleFeedbackButtonClick}
            handleFeedbackTextChange={handleFeedbackTextChange}
            handleFeedbackSubmit={handleFeedbackSubmit}
            setCurrentQuestion={setCurrentQuestion}
          />
        </div>

        <div className="void" />
      </div>

      {/* Right Side - Summary Display */}
      {samenvattingText && (
        <div className="eindegesprek-right">
          <h1>Samenvatting</h1>
          <textarea
            className="samenvatting-textarea"
            value={samenvattingText}
            onChange={(e) => setSamenvattingText(e.target.value)}
            placeholder="Samenvatting verschijnt hier..."
          />
          <button className="copy-button" onClick={handleCopySamenvatting}>
            Kopieer Samenvatting
          </button>
          <div className="export-container">
            <div className="dropdown-container">
              <label className="dropdown-label">Export Type:</label>
              <select className="dropdown" value={exportType} onChange={(e) => setExportType(e.target.value)}>
                <option value="PDF">PDF</option>
                <option value="Text">Text</option>
              </select>
              <button className="action-button" onClick={handleExportSamenvatting}>
                Exporteer Samenvatting
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Feedback Section for Mobile */}
      <div className="mobile-only">
        <FeedbackSection
          statements={statements}
          currentQuestion={currentQuestion}
          ratings={ratings}
          ratingsSubmitted={ratingsSubmitted}
          feedbackDropdownVisible={feedbackDropdownVisible}
          feedbackText={feedbackText}
          feedbackSent={feedbackSent}
          handleRatingChange={handleRatingChange}
          handleSubmitRatings={handleSubmitRatings}
          handleFeedbackButtonClick={handleFeedbackButtonClick}
          handleFeedbackTextChange={handleFeedbackTextChange}
          handleFeedbackSubmit={handleFeedbackSubmit}
          setCurrentQuestion={setCurrentQuestion}
        />
      </div>
    </div>
  );
};

export default EindeGesprekPage;
