import "./abbonement.css";
import React, { useState, useEffect } from "react";
import checkingGif from "../images/check.gif"; // Optional: A loading indicator or similar image
import { getJwtToken } from "../auth"; // Import JWT token function
import config from "../config"; // Import configuration
const reactServer = config.reactServer;

const Abonnement: React.FC = () => {
  const [profile, setProfile] = useState<any>(null); // Define the profile state
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState<string | null>(null); // Error state
  const [actionMessage, setActionMessage] = useState<string>(""); // Message for actions
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null); // State for selected subscription
  const [licenseAgreementChecked, setLicenseAgreementChecked] = useState(false); // State for checkbox
  const [organizationName, setOrganizationName] = useState(""); // State for organization name
  const [paymentProcessing, setPaymentProcessing] = useState(false); // State for payment processing (placeholder)
  const [contactFormData, setContactFormData] = useState({
    fullName: "",
    email: "",
    institution: "",
    function: "",
    userCount: "",
  }); // State for contact form data

  // Define the subscription options
  const subscriptionOptions = [
    {
      pricing_id: 2,
      code: "pro",
      name: "Zorgverlener Go",
      customer_type: "Zorgverlener",
      price: "€29,99/maand",
      features: ["300 min/maand", "Samenvattingen", "1 gebruiker"],
      price_num: 29.99,
      type: "month",
      limit: 300,
    },
    {
      pricing_id: 3,
      code: "plus",
      name: "Zorgverlener Plus",
      customer_type: "Zorgverlener",
      price: "€59,99/maand",
      features: ["Ongelimiteerd gebruik", "Samenvattingen", "1 gebruiker"],
      price_num: 59.99,
    },
    {
      pricing_id: 3,
      code: "business_pro",
      name: "Zorginstelling Go",
      customer_type: "Zorginstelling",
      price: "€59,99/maand per gebruiker",
      price_num: 59.99,
      features: [
        "Ongelimiteerd gebruik",
        "Samenvattingen",
        "Tot 10 gebruikers",
      ],
    },
    {
      code: "groot_gebruiker",
      name: "Zorginstelling Plus",
      customer_type: "Zorginstelling",
      price: "€49,99/maand per gebruiker",
      features: ["Vanaf 11 of meer gebruikers"],
      price_num: 49.99,
    },
  ];

  useEffect(() => {
    setLoading(true); // Start loading
    fetchProfile();
  }, []);

  const fetchProfile = () => {
    const token = getJwtToken();

    if (token) {
      fetch(`${reactServer}account/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
        })
        .catch((error) => {
          setError(`Error fetching profile: ${error.message}`);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    }
  };

  // Function to cancel subscription
  const cancelSubscription = async () => {
    if (profile?.subscription[0] !== "busy") {
      setActionMessage("Je kunt een niet-druk abonnement niet annuleren.");
      return;
    }

    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/cancel-subscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setActionMessage("Je abonnement is geannuleerd.");
      // Fetch updated profile data
      fetchProfile();
    } else {
      setActionMessage("Fout bij het annuleren van het abonnement.");
    }
  };

  // Function to select a subscription
  const selectSubscription = (subscriptionCode: string) => {
    const option = subscriptionOptions.find(
      (option) => option.code === subscriptionCode
    );
    setSelectedSubscription(option);
    setLicenseAgreementChecked(false); // Reset the checkbox
    setOrganizationName(""); // Reset the organization name
    setActionMessage(""); // Clear any previous messages
    setContactFormData({
      fullName: "",
      email: "",
      institution: "",
      function: "",
      userCount: "",
    }); // Reset form data
  };

  // Function to handle payment (placeholder)
  const handlePayment = async () => {
    setPaymentProcessing(true);
    // Placeholder logic for payment processing

    console.log(selectedSubscription);

    let body: any = {
      subscriptionType: "monthly",
      customer_type: selectedSubscription.customer_type,
      amount: selectedSubscription.price_num,
      license: selectedSubscription.name,
      pricing_id: selectedSubscription.pricing_id,
    };

    console.log(body);

    console.log("Test====");

    const token = getJwtToken();
    const response = await fetch(`${reactServer}payments/create-payment`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      // Extract and log the response body
      response
        .json()
        .then((responseBody) => {
          console.log("Response Body:", responseBody);

          const linkToLoad = responseBody.paymentUrl;

          if (linkToLoad) {
            // Redirect to the link on the same page
            window.location.href = linkToLoad;
          } else {
            setActionMessage("Geen geldige link ontvangen om te laden.");
          }

          // If you want to display some value from the response in the message:
          setActionMessage("Je abonnement is geannuleerd.");

          // Fetch updated profile data
          fetchProfile();
        })
        .catch((error) => {
          console.error("Error parsing response body:", error);
        });
    } else {
      setActionMessage("Fout bij het annuleren van het abonnement.");
    }

    // setTimeout(() => {
    //   setPaymentProcessing(false);
    //   setActionMessage("Betaling succesvol! Uw abonnement is bijgewerkt.");
    //   // Reset selection and fetch updated profile
    //   setSelectedSubscription(null);
    //   fetchProfile();
    // }, 2000); // Simulate payment delay
  };

  // Function to handle contact form submission
  const handleContactFormSubmit = () => {
    // Placeholder logic for form submission
    setActionMessage(
      "Uw aanvraag is verstuurd. We nemen zo snel mogelijk contact met u op."
    );
    setSelectedSubscription(null);
  };

  // Function to go back to subscription options
  const goBackToOptions = () => {
    setSelectedSubscription(null);
    setLicenseAgreementChecked(false);
    setOrganizationName("");
    setActionMessage("");
    setContactFormData({
      fullName: "",
      email: "",
      institution: "",
      function: "",
      userCount: "",
    });
  };

  // Function to pause subscription
  const pauseSubscription = async () => {
    if (profile?.subscription[0] !== "busy") {
      setActionMessage("Je kunt een niet-druk abonnement niet pauzeren.");
      return;
    }

    const token = getJwtToken();
    const response = await fetch(`${reactServer}account/pause-subscription`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setActionMessage("Je abonnement is gepauzeerd.");
      // Fetch updated profile data
      fetchProfile();
    } else {
      setActionMessage("Fout bij het pauzeren van het abonnement.");
    }
  };

  // Helper function to get subscription name
  const getSubscriptionName = (code: string): string => {
    if (code === "lite") return "Proefversie";
    if (code === "") return "Geen Abonnement";
    const option = subscriptionOptions.find((option) => option.code === code);
    return option ? option.name : code;
  };

  // Helper function to parse date
  const parseDate = (dateString: string): string => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      // Assuming date format is 'dd-mm-yyyy'
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Month is 0-based
      const year = parseInt(parts[2], 10);
      const date = new Date(year, month, day);
      return date.toLocaleDateString();
    }
    return dateString;
  };

  // Loading and error handling
  if (loading) {
    return (
      <div className="loading-container">
        <img src={checkingGif} alt="Loading..." />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // Determine if user is an organization admin
  const isOrganizationAdmin = profile && profile.org_id;

  // Get available subscriptions
  let availableSubscriptions = subscriptionOptions.filter(
    (option) => option.code !== profile.subscription[0]
  );

  if (isOrganizationAdmin) {
    // If the user is an organization admin, show only "Groot gebruiker"
    availableSubscriptions = subscriptionOptions.filter(
      (option) => option.code === "groot_gebruiker"
    );
  }
  // Note: For regular users, include all options including "Groot gebruiker"

  return (
    <div className="abbonement-page">
      <div
        className={`abonnement-page-container ${
          selectedSubscription || isOrganizationAdmin ? "narrow" : ""
        }`}
      >
        {selectedSubscription ? (
          selectedSubscription.code === "groot_gebruiker" ? (
            <>
              <button className="back-button" onClick={goBackToOptions}>
                Terug
              </button>
              <p className="selection-title">
                Met dit formulier komt u in contact met ons voor het aanmaken
                van een account met meer dan 10 gebruikers. Wij zullen uw
                aanvraag zo snel mogelijk behandelen.
              </p>
              <div className="contact-form">
                <div className="form-group">
                  <label htmlFor="fullName">Voor- en Achternaam*</label>
                  <input
                    type="text"
                    id="fullName"
                    value={contactFormData.fullName}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        fullName: e.target.value,
                      })
                    }
                    placeholder="Vul uw voornaam in"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">E-mailadres*</label>
                  <input
                    type="email"
                    id="email"
                    value={contactFormData.email}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        email: e.target.value,
                      })
                    }
                    placeholder="Vul uw e-mailadres in"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="institution">
                    Zorginstelling/Ziekenhuis*
                  </label>
                  <input
                    type="text"
                    id="institution"
                    value={contactFormData.institution}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        institution: e.target.value,
                      })
                    }
                    placeholder="Werkzaam bij"
                  />
                </div>
                <div className="form-group">
                  <label>Functie*</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Arts (Specialist)"
                        checked={
                          contactFormData.function === "Arts (Specialist)"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Arts (Specialist)</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Verpleegkundige"
                        checked={contactFormData.function === "Verpleegkundige"}
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Verpleegkundige</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Co-assistent"
                        checked={contactFormData.function === "Co-assistent"}
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Co-assistent</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Overige zorgverleners"
                        checked={
                          contactFormData.function === "Overige zorgverleners"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Overige zorgverleners</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="function"
                        value="Directie/Management"
                        checked={
                          contactFormData.function === "Directie/Management"
                        }
                        onChange={(e) =>
                          setContactFormData({
                            ...contactFormData,
                            function: e.target.value,
                          })
                        }
                      />
                      <span>Directie/Management</span>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="userCount">
                    Geef een indicatie voor het aantal gebruikers dat u verwacht
                    te willen gebruiken
                  </label>
                  <input
                    type="number"
                    id="userCount"
                    value={contactFormData.userCount}
                    onChange={(e) =>
                      setContactFormData({
                        ...contactFormData,
                        userCount: e.target.value,
                      })
                    }
                  />
                </div>
                <button
                  className="action-button submit-button"
                  onClick={handleContactFormSubmit}
                  disabled={
                    !contactFormData.fullName.trim() ||
                    !contactFormData.email.trim() ||
                    !contactFormData.institution.trim() ||
                    !contactFormData.function
                  }
                >
                  Verstuur aanvraag
                </button>
              </div>
            </>
          ) : (
            <>
              <button className="back-button" onClick={goBackToOptions}>
                Terug
              </button>
              <h2 className="selection-title">
                U heeft gekozen voor het volgende abonnement:
              </h2>
              <div className="subscription-selection">
                <div className="subscription-card selected-card">
                  <h3>{selectedSubscription.name}</h3>
                  <p className="subscription-price">
                    {selectedSubscription.price}
                  </p>
                  <ul className="subscription-features">
                    {selectedSubscription.features.map(
                      (feature: string, index: number) => (
                        <li key={index}>{feature}</li>
                      )
                    )}
                  </ul>
                </div>
                {selectedSubscription.code === "business_pro" && (
                  <div className="organization-input">
                    <label htmlFor="organizationName">
                      Naam van uw organisatie:
                    </label>
                    <input
                      type="text"
                      id="organizationName"
                      value={organizationName}
                      onChange={(e) => setOrganizationName(e.target.value)}
                    />
                  </div>
                )}
                <div className="license-agreement">
                  <input
                    type="checkbox"
                    id="licenseAgreement"
                    checked={licenseAgreementChecked}
                    onChange={(e) =>
                      setLicenseAgreementChecked(e.target.checked)
                    }
                  />
                  <label htmlFor="licenseAgreement">
                    Ik heb de licentieovereenkomst gelezen en ik ga akkoord met
                    de licentieovereenkomst.
                  </label>
                </div>
                <button
                  className="action-button payment-button"
                  onClick={handlePayment}
                  disabled={
                    !licenseAgreementChecked ||
                    (selectedSubscription.code === "business_pro" &&
                      !organizationName.trim())
                  }
                >
                  {paymentProcessing
                    ? "Bezig met verwerken..."
                    : "Ga naar betaling"}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            <h2 className="abonnement-title">Abonnement Details</h2>

            {profile && (
              <div className="abonnement-details">
                <p className="abonnement-info">
                  <strong>Huidige Abonnementstype:</strong>{" "}
                  {getSubscriptionName(profile.subscription)}
                </p>
                <p className="abonnement-info">
                  <strong>Abonnement geldig tot:</strong>{" "}
                  {new Date(profile.subscription_end_date).toLocaleDateString()}
                </p>

                {/* Display cancellation message if the subscription is from an organization */}
                {profile.subscription[3] ? (
                  <p className="organization-message">
                    Neem contact op met uw organisatie om uw abonnement te
                    stoppen.
                  </p>
                ) : (
                  <div className="user-actions">
                    {profile.subscription[0] !== "lite" && (
                      <>
                        <button
                          className="action-button cancel-button"
                          onClick={cancelSubscription}
                        >
                          Annuleer Abonnement
                        </button>
                        <button
                          className="action-button pause-button"
                          onClick={pauseSubscription}
                        >
                          Pauzeer Abonnement
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* Render subscription options */}
            {profile && (
              <div
                className={`subscription-options ${
                  availableSubscriptions.length === 1 ? "single-option" : ""
                }`}
              >
                {availableSubscriptions.map((option) => (
                  <div
                    className={`subscription-card ${
                      availableSubscriptions.length === 1 ? "single-card" : ""
                    }`}
                    key={option.code}
                  >
                    <h3>{option.name}</h3>
                    <p className="subscription-price">{option.price}</p>
                    <ul className="subscription-features">
                      {option.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                    <button
                      className="action-button upgrade-button"
                      onClick={() => selectSubscription(option.code)}
                    >
                      {option.code === "groot_gebruiker"
                        ? "Contact opnemen"
                        : `Kies ${option.name}`}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {actionMessage && <p className="action-message">{actionMessage}</p>}
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default Abonnement;
