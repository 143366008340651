export interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "Wat is TolkChat?",
    answer:
      "TolkChat is een vertaalsoftware ontwikkeld voor de zorg om de taalbarrière te verminderen tussen patiënt en zorgverlener. Het gebruikt Kunstmatige Intelligentie en Taalmodellen om accurate en betrouwbare vertaaloplossingen te bieden en ondersteunt zowel spraak- als tekstvertaling op telefoons, tablets en laptops.",
  },
  {
    question: "Op welke apparaten kan ik TolkChat gebruiken?",
    answer:
      "TolkChat is compatibel met smartphones, tablets en laptops die draaien op iOS, Android, Windows of macOS. Je kunt de software als webapplicatie voor desktop gebruiken of de web app downloaden in je browser.",
  },
  {
    question: "Heb ik een internetverbinding nodig om TolkChat te gebruiken?",
    answer:
      "Ja, een internetverbinding is nodig voor vertalingen. TolkChat gebruikt een beveiligde end-to-end versleutelde verbinding om data te vertalen zonder gegevens op te slaan.",
  },
  {
    question: "Heb ik een microfoon nodig voor TolkChat?",
    answer:
      "Ja, een microfoon is essentieel voor het gebruik van TolkChat. Een ingebouwde microfoon is voldoende, maar een externe microfoon kan zorgen voor betere geluidskwaliteit en nauwkeurigere spraakherkenning.",
  },
  {
    question: "Welke talen ondersteunt TolkChat?",
    answer:
      "TolkChat ondersteunt een breed scala aan talen, waaronder Engels, Spaans, Frans, Arabisch, Chinees en meer. Je kunt bij de start de taal kiezen die past bij je patiënt, en er worden continu nieuwe talen en dialecten toegevoegd.",
  },
  {
    question: "Hoe nauwkeurig zijn de vertalingen van TolkChat?",
    answer:
      "De vertalingen zijn geoptimaliseerd voor zorggerelateerde communicatie en bieden hoge nauwkeurigheid, vooral bij medische terminologie. Nauwkeurigheid kan variëren met de complexiteit van termen en taal.",
  },
  {
    question: "Is TolkChat veilig voor gebruik in de zorg?",
    answer:
      "Ja, TolkChat voldoet aan strenge privacy- en beveiligingsrichtlijnen, waaronder de AVG, om de privacy van patiëntgegevens te beschermen.",
  },
  {
    question: "Kan TolkChat medische termen vertalen?",
    answer:
      "Ja, TolkChat is speciaal ontworpen om medische terminologie te begrijpen en nauwkeurig te vertalen, wat essentiële medische communicatie makkelijker maakt.",
  },
  {
    question:
      "Mijn collega wil ook gebruik maken van TolkChat. Hoe kan hij/zij beginnen?",
    answer:
      "Je collega kan zich registreren via het team of bedrijf met een unieke code die de organisatie-admin ontvangt bij het aanmaken van een organisatie-account.",
  },
  {
    question: "Is TolkChat CE-gecertificeerd?",
    answer:
      "Nee, TolkChat wordt niet beschouwd als een medisch hulpmiddel en heeft daarom geen CE-certificering.",
  },
  {
    question: "Kan ik TolkChat gebruiken voor groepsgesprekken?",
    answer:
      "Ja, TolkChat ondersteunt groepsgesprekken en kan spraak van meerdere mensen herkennen en vertalen, wat het geschikt maakt voor communicatie in teams.",
  },
  {
    question: "Wat betekent end-to-end encryptie bij TolkChat?",
    answer:
      "End-to-end encryptie zorgt ervoor dat alle communicatie tussen zorgverleners en patiënten volledig versleuteld is, zodat alleen de ontvanger toegang heeft tot de inhoud.",
  },
  {
    question: "Hoe is TolkChat beveiligd?",
    answer:
      "TolkChat is beveiligd met end-to-end encryptie en voldoet aan AVG-normen. Ons doel is om een NEN-certificering te verkrijgen voor gegevensverwerking volgens de hoogste standaarden.",
  },
  {
    question: "Slaan jullie mijn gegevens op of verwerken jullie deze?",
    answer:
      "Nee, TolkChat verwerkt geen patiëntgegevens. Alle communicatie blijft privé zonder dat TolkChat toegang heeft tot medische of persoonlijke gegevens.",
  },
  {
    question:
      "Hoe zorgen jullie ervoor dat de kwaliteit van TolkChat gewaarborgd blijft?",
    answer:
      "We werken continu aan softwareverbetering via pilots om gebruikerservaring en effectiviteit te testen en op basis hiervan updates door te voeren.",
  },
  {
    question: "Wanneer gebruik ik TolkChat?",
    answer:
      "TolkChat biedt een oplossing voor taalbarrières zonder lange wachttijden voor een tolk, ideaal voor communicatie met anderstalige patiënten.",
  },
  {
    question:
      "Een specifiek woord wordt herhaaldelijk niet herkend door de vertaler. Wat kan ik doen?",
    answer:
      "Neem contact op via het feedbackformulier. We zullen het probleem onderzoeken en het woord toevoegen aan onze vertaaldatabase voor betere nauwkeurigheid.",
  },
  {
    question: "Kan het platform medische documenten vertalen?",
    answer:
      "Nee, op dit moment richt TolkChat zich alleen op vertaling voor gesprekken en niet op documenten.",
  },
  {
    question: "Wat zijn de kosten van het lidmaatschap?",
    answer: "De kosten zijn nog in ontwikkeling.",
  },
  {
    question: "Waar kan ik terecht voor technische ondersteuning?",
    answer:
      "Voor technische ondersteuning kun je contact opnemen via e-mail, onze hulplijn, of een feedbackformulier invullen.",
  },
  {
    question:
      "Wordt er gebruik gemaakt van machine learning technologie bij vertalingen?",
    answer:
      "Ja, TolkChat maakt gebruik van een groot taalmodel dat specifiek is getraind op taalherkenning voor nauwkeurige vertalingen.",
  },
  {
    question:
      "Is er een samenwerkingsfunctie waarmee verschillende zorgverleners kunnen samenwerken in de communicatie met de patiënt of cliënt?",
    answer:
      "Ja, TolkChat biedt functies voor samenwerking tussen zorgverleners, waarbij meerdere zorgverleners kunnen communiceren in dezelfde sessie.",
  },
  {
    question: "Hoe kan ik mijn abonnement opzeggen of wijzigen?",
    answer:
      "TolkChat werkt met maandelijkse licenties. Je kunt je abonnement voor onbepaalde tijd verlengen of opzeggen.",
  },
  {
    question: "Zijn er limieten aan het aantal karakters per gesprek?",
    answer: "Er is een limiet van 15 minuten per gesprek.",
  },
  {
    question:
      "Kan ik ervoor kiezen om documenten of gesprekken met een patiënt op te slaan?",
    answer:
      "Ja, met de samenvattingstool kun je gesprekken samenvatten en downloaden als een TXT- of PDF-bestand voor integratie in een elektronisch patiëntendossier.",
  },
];

export default faqs;
