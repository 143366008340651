import React, { useState, useEffect } from "react";
import { getJwtToken } from "../auth";
import config from "../config";
import AddUserPopUp from "../components/PopUpModal";
import "./profieloverzicht.css";

const reactServer = config.reactServer;

const UserOverview: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [subscriptionType, setSubscriptionType] = useState<string>("no");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");

  // Get all the data from the user
  const [profile, setProfile] = useState<any>({
    is_admin: false,
    is_org_admin: false,
  });

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Profile set");
          console.log(data);
          setProfile(data);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  // Get the data from all the users
  useEffect(() => {
    const token = getJwtToken();

    fetch(reactServer + "account/users", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUsers(data);
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  const handleSubscriptionUpdate = () => {
    if (!selectedUserId) {
      console.error("Please select a user");
      return;
    }

    const token = getJwtToken();

    fetch(reactServer + "account/update_subscription_profieloverzicht", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: selectedUserId,
        subscriptionType,
        subscriptionEndDate,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update subscription");
        }
        return response.json();
      })
      .then(() => {
        // Reload the page after successful subscription update
        window.location.reload();
      })
      .catch((error) => console.error("Error updating subscription:", error));
  };

  // Handle adding a new user
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleCancel = () => {
    setIsFormVisible(false);
  };

  const handleConfirm = async (data: {
    userName: string;
    email: string;
  }): Promise<void> => {
    try {
      console.log("User data submitted:", data);

      const org = JSON.parse(JSON.stringify(profile));

      const userData = {
        naam: data.userName,
        email: data.email,
        organisation: org.organisation,
        org_id: org.org_id,
      };

      const token = getJwtToken();

      const response = await fetch(
        `${reactServer}organisation/add-user-to-org`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Unable to connect to the server."
        );
      }

      console.log("User successfully added to the organization.");
      window.location.reload();
    } catch (error: any) {
      console.error("Error adding user to organization:", error.message);
      alert(`Error: ${error.message}`);
    } finally {
      setIsFormVisible(false);
    }
  };

  return (
    <div className="profile-container">
      {!profile.is_admin && (
        <div className="profile-page">
          Pagina alleen beschikbaar voor administratoren.
        </div>
      )}
      {profile.is_admin && (
        <div className="users-container">
          <div className="subscription-form">
            <h2>Update Subscription</h2>
            <div className="form-group">
              <label htmlFor="user-select">Selecteer gebruiker:</label>
              <select
                id="user-select"
                value={selectedUserId || ""}
                onChange={(e) => setSelectedUserId(parseInt(e.target.value))}
              >
                <option value="">Selecteer een gebruiker</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.naam}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="subscription-type">Subscription Type:</label>
              <select
                id="subscription-type"
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
              >
                <option value="no">no</option>
                <option value="lite">lite</option>
                <option value="pro">pro</option>
                <option value="organisation">
                  organisatie (alleen voor testen)
                </option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="subscription-end-date">
                Subscription End Date:
              </label>
              <input
                id="subscription-end-date"
                type="date"
                value={subscriptionEndDate}
                onChange={(e) => setSubscriptionEndDate(e.target.value)}
              />
            </div>
            <button onClick={handleSubscriptionUpdate}>
              Update Subscription
            </button>
          </div>

          <h2 className="title">User Overview</h2>
          <div className="table-container">
            <table className="users-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Naam</th>
                  <th>Email</th>
                  <th>SuperAdmin</th>
                  <th>Geregistreerd</th>
                  <th>Gebruik</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td data-label="ID">{user.id}</td>
                    <td data-label="Naam">{user.naam}</td>
                    <td data-label="Email">{user.email}</td>
                    <td data-label="SuperAdmin">{user.admin ? 1 : 0}</td>
                    <td data-label="Geregistreerd">{user.registratie ? 1 : 0}</td>
                    <td data-label="Gebruik">{user.usage}</td>
                    <td data-label="Type">{user.subscription}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="user-overview">
            <button
              className="add-user-button"
              onClick={() => setIsFormVisible((prev) => !prev)}
            >
              Add User to Organisation
            </button>

            {isFormVisible && (
              <AddUserPopUp
                title="Add New User"
                message="Please provide user details."
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOverview;