import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";

import Error from "../notifications/Error";

import config from "../config";

// Import the eye icons
import eyeOpen from "../images/eye_open.png";
import eyeClosed from "../images/eye_closed.png";

const reactServer = config.reactServer;

const RegistrationForm: React.FC = () => {
  const [naam, setNaam] = useState("");
  const [email, setEmail] = useState("");
  const [wachtwoord, setWachtwoord] = useState("");
  const [herhaal, setHerhaal] = useState("");
  const [termsAndServices, setTermsAndServices] = useState(false);
  const [licentieOvereenkomst, setLicentieOvereenkomst] = useState(false);
  const [twoStepAvailable, setTwoStepAvailable] = useState(false);
  const [twoStepCode, setTwoStepCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationType, setOrganizationType] = useState("Organisatie"); // New state variable
  const [contractFile, setContractFile] = useState<File | null>(null);

  const [overeenkomstText, setOvereenkomstText] = useState(""); // State for overeenkomst text
  const [overeenkomstFile, setOvereenkomstFile] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*\W).{12,}$/;
    return regex.test(password);
  };

  useEffect(() => {
    // Update overeenkomst text based on the selected organization type
    switch (organizationType) {
      case "Organisatie":
        setOvereenkomstText(
          "Ik heb de organisatie licentieovereenkomst gelezen en ik ga "
        );
        setOvereenkomstFile("/path/to/organisatie-overeenkomst.docx");
        break;
      case "Eenmanszaak":
        setOvereenkomstText(
          "Ik heb de eenmanszaak licentieovereenkomst gelezen en ik ga "
        );
        setOvereenkomstFile("/path/to/organisatie-overeenkomst.docx");
        break;
      case "Pilot":
        setOvereenkomstText("Ik heb de pilotovereenkomst gelezen en ik ga ");
        setOvereenkomstFile(
          "/overeenkomsten/Pilotovereenkomst Wellcom Translation.docx"
        );
        break;
      default:
        setOvereenkomstText("");
    }
  }, [organizationType]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      naam: naam,
      email: email.toLowerCase(),
      wachtwoord: wachtwoord,
      termsAndServices: termsAndServices,
      licentieOvereenkomst: licentieOvereenkomst, // Include licentieOvereenkomst in formData
      organization: organization,
      organizationType: organizationType, // Include organizationType in formData
      contractFile: contractFile,
    };

    console.log(formData);

    // Validate form fields
    if (
      !naam ||
      !email ||
      !organization ||
      !organizationType ||
      !wachtwoord ||
      !herhaal
    ) {
      setError("Alle velden dienen ingevuld te worden.");
      return;
    }

    if (!validatePassword(wachtwoord)) {
      setError(
        "Het wachtwoord moet minimaal 12 karakters lang zijn en ten minste één hoofdletter en één symbool bevatten."
      );
      return;
    }

    if (herhaal !== wachtwoord) {
      setError("Wachtwoorden komen niet overeen.");
      return;
    }

    if (!email.includes("@")) {
      setError("Dit is geen geldig e-mailadres.");
      return;
    }

    if (!termsAndServices) {
      setError("Je moet akkoord gaan met de eindgebruikersvoorwaarden.");
      return;
    }

    if (!licentieOvereenkomst) {
      setError("Je moet akkoord gaan met de licentieovereenkomst.");
      return;
    }

    try {
      const response = await fetch(reactServer + "organisation/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || "Geen verbinding met de server.");
        return;
      }

      const data = await response.json();

      if (response.status === 201) {
        setSuccess(data.message);
        setTwoStepAvailable(true);
        localStorage.setItem("email", email.toLowerCase());
      } else if (response.status === 200) {
        setSuccess(data.message);
        localStorage.setItem("email", email.toLowerCase());
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Geen verbinding met de server.");
    }
  };

  const handleVerification = async (email: string, code: string) => {
    const data = {
      email: email.toLowerCase(),
      code: code,
    };

    try {
      const response = await fetch(reactServer + "account/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        navigate("/login", { replace: true });
      } else {
        const responseData = await response.json();
        setError(responseData.message);
      }
    } catch (error) {
      setError("Geen verbinding met de server.");
    }
  };

  const handleVerificationSubmit = async () => {
    await handleVerification(email, twoStepCode);
  };

  const handleContractFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setContractFile(files[0]);
    }
  };

  const handleCloseError = () => {
    setError("");
  };

  return (
    <div className="registration-container-org">
      {error && <Error message={error} onClose={handleCloseError} />}
      <h1 className="page-title">Registratie Organisatie</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className="page-text">
          Met dit formulier maakt u een admin account voor een organisatie met
          maximaal 10 gebruikers aan. Via dit account kunt u medewerkers
          toeoegen in uw profiel zodra uw account actief is.
        </div>
        <input
          type="text"
          name="organization"
          placeholder="Naam organisatie"
          value={organization}
          onChange={(event) => setOrganization(event.target.value)}
        />
        <select
          name="organizationType"
          value={organizationType}
          className="organisatietype"
          onChange={(event) => setOrganizationType(event.target.value)}
        >
          <option value="Organisatie">Organisatie</option>
          {/* <option value="Eenmanszaak">Eenmanszaak</option> */}
          <option value="Pilot">Pilot</option>
        </select>
        <input
          type="text"
          name="naam"
          placeholder="Uw volledige naam"
          value={naam}
          onChange={(event) => setNaam(event.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Uw administrator e-mailadres"
          value={email}
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
        />

        <div className="password-field">
          <input
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            name="wachtwoord"
            placeholder="Wachtwoord"
            value={wachtwoord}
            onChange={(event) => setWachtwoord(event.target.value)}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Verberg wachtwoord" : "Toon wachtwoord"}
          >
            <img
              src={showPassword ? eyeClosed : eyeOpen}
              alt={showPassword ? "Verberg wachtwoord" : "Toon wachtwoord"}
              className="eye-icon"
            />
          </button>
        </div>

        {/* Repeat Password Field with Toggle */}
        <div className="password-field">
          <input
            type={showRepeatPassword ? "text" : "password"}
            autoComplete="new-password"
            name="herhaal"
            placeholder="Herhaal wachtwoord"
            value={herhaal}
            onChange={(event) => setHerhaal(event.target.value)}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
            aria-label={
              showRepeatPassword ? "Verberg wachtwoord" : "Toon wachtwoord"
            }
          >
            <img
              src={showRepeatPassword ? eyeClosed : eyeOpen}
              alt={
                showRepeatPassword ? "Verberg wachtwoord" : "Toon wachtwoord"
              }
              className="eye-icon"
            />
          </button>
        </div>
        <div className="terms-and-services">
          <input
            type="checkbox"
            name="termsAndServices"
            checked={termsAndServices}
            onChange={(event) => setTermsAndServices(event.target.checked)}
          />
          <label htmlFor="termsAndServices">
            Ik heb de eindgebruikersvoorwaarden gelezen en ik ga akkoord met{" "}
            <a
              href="/overeenkomsten/Gebruikersovereenkomst Wellcom Translation.docx"
              download
            >
              deze
            </a>{" "}
            voorwaarden.*
          </label>
        </div>
        <div className="terms-and-services">
          <input
            type="checkbox"
            name="licentieOvereenkomst"
            checked={licentieOvereenkomst}
            onChange={(event) => setLicentieOvereenkomst(event.target.checked)}
          />
          <label htmlFor="licentieOvereenkomst">
            {overeenkomstText}
            <a href={overeenkomstFile} download>
              hiermee
            </a>{" "}
            akkoord.**
          </label>
        </div>
        <button type="submit" className="submit-button">
          Registreren
        </button>
        {success && (
          <p style={{ color: "black", fontSize: "small" }}>{success}</p>
        )}
        {twoStepAvailable && (
          <div>
            <label style={{ marginTop: "15px" }} htmlFor="termsAndServices">
              We hebben u een mail verzonden met een verificatiecode (check uw
              spam folder). Uw tweestaps verificatie code:
            </label>
            <input
              type="code"
              name="code"
              placeholder="Code"
              className="twostepcode"
              value={twoStepCode}
              onChange={(event) => setTwoStepCode(event.target.value)}
            />
            <button type="button" onClick={handleVerificationSubmit}>
              Bevestigen
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default RegistrationForm;
