import React, { useState, useEffect } from 'react';
import './SettingsMenu.css';

import { getJwtToken } from '../auth';
import config from '../config';
const reactServer = config.reactServer;

interface SettingsMenuProps {
  autoPlaySetting: string; // "beide", "patient", "arts", "uit"
  setAutoPlay: (setting: string) => void;
  language: string;
  setLanguage: (lang: string) => void;
  availableLanguages: string[];
  onClose: () => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ autoPlaySetting, setAutoPlay, language, setLanguage, availableLanguages, onClose }) => {
  const [profile, setProfile] = useState<any>(null);
  const [localAutoPlaySetting, setLocalAutoPlaySetting] = useState<string>(autoPlaySetting);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + 'account/profile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setProfile(data);
          setLocalAutoPlaySetting(data.autoplay || "uit");
        })
        .catch(error => {
          console.error('Error fetching profile:', error);
        });
    }
  }, []);

  const handleAutoPlayChange = async (newSetting: string) => {
    try {
      const token = getJwtToken();

      const response = await fetch(reactServer + 'account/settings/autoplay', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: profile.email, autoplaySetting: newSetting }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setLocalAutoPlaySetting(newSetting);
      setAutoPlay(newSetting); // Update parent state if needed
    } catch (error) {
      console.error('Error updating AutoPlay setting:', error);
    }
  };

  return (
    <div className="settings-menu">
      <div>
        <button className="close-btn" onClick={onClose}>X</button>
        <h3>Instellingen</h3> 
      </div>
      <div className="setting-item">
        <label>Automatisch Afspelen</label>
        <div className="button-group">
          {["uit", "patiënt", "arts", "beide"].map((option) => (
            <button
              key={option}
              onClick={() => handleAutoPlayChange(option)}
              className={localAutoPlaySetting === option ? "active" : ""}
            >
              {option === "uit" ? "Uit" : option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
      </div>
      <div className="setting-item">
        <label>
          Voertaal pagina
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            {availableLanguages.map(lang => (
              <option key={lang} value={lang}>{lang}</option>
            ))}
          </select>
        </label>
      </div>
    </div>
  );
};

export default SettingsMenu;
