// src/views/faq.tsx

import React, { useState } from "react";
import "./infopages.css";
import faqs, { FAQ } from "../faqs";

const FAQItem: React.FC<{ faq: FAQ }> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`reason ${isOpen ? 'open' : ''}`} onClick={toggleAnswer}>
      <h2 className="reason-title">{faq.question}</h2>
      {isOpen && <p className="reason-description">{faq.answer}</p>}
    </div>
  );
};

const InfoPage: React.FC = () => {
  return (
    <div className="faq-page">
      <h1 className="faq-title">Frequently Asked Questions</h1>

      {faqs.map((faq: FAQ, index: number) => (
        <FAQItem key={index} faq={faq} />
      ))}
    </div>
  );
};

export default InfoPage;
